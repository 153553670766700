/* 
File: budget.js
Description: called from Budgets.vue, returns budget data for each version: 1, year, region and category.
*/
import Api from '@/api/budget'

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_BUDGET_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_budget(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_BUDGET_LIST', res.data)
        } else {
          throw `Load budget list status: ${status}`
        }
      } catch (err) {
        throw `Load Rate list: ${err}`
      } finally {
      }
    },

    async LOAD_BUDGET_BY_ID({ commit }, budget_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await await Api.load_budget_by_id(budget_id, lang)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_BUDGET_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      } finally {
      }
    },

    async GET_BUDGET_AMOUNT({ commit }, budget_id) {
      const budget = budgetsTable.find((element) => element.budget_id == budget_id)
      return budget.amount
    },

    async ADD_BUDGET({ commit }, theBudget) {
      try {
        const res = await Api.add_budget(theBudget)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      } finally {
      }
    },

    async UPD_BUDGET({ commit }, payload) {
      try {
        const { id, theBudget } = payload
        const res = await Api.upd_budget(id, theBudget)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      } finally {
      }
    },

    async DEL_BUDGET({ commit }, budget_id) {
      try {
        const res = await Api.del_budget(budget_id)
        if (res.status === 200) {
          commit('DEL_BUDGET_FROM_LIST', budget_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

  },

  mutations: {
    SET_BUDGET_LIST(state, list) {
      state.list = [...list];
    },
    DEL_BUDGET_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.budget_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {
    // Should we delete it as it is slower than fitering in the component?
    budgetsByRegion: (state) => (region) => {
      return !region ? state.list : state.list.filter(el => el.fk_region === region)
    },
    budgetsByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(el => el.year === year)
    },

    budgetsByRegionAndYear: (state) => (region, year) => {
      // Only for periodic maintenance
      return region && year
        ? state.list.filter(el =>
          el.fk_region === region &&
          el.year === year //&& [4, 6].includes(el.fk_work_category)
        )
        : [];
    },

    budgetsByCategory: (state, getters) => (region, year, fk_work_category) => {
      const res = getters.budgetsByRegionAndYear(region, year)
      return !fk_work_category ? res : res.filter(el => el.fk_work_category === fk_work_category)
    },

    categoriesInBudget: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)
      return [...new Set(res.reduce((acc, item) => {
        if (item.fk_work_category !== null) acc.push(item.fk_work_category)
        return acc
      }, []))].sort((a, b) => a - b).map(fk_work_category => ({ fk_work_category }))
    },
    /*    const uniqueCategories = Array.from(
          new Map(res.filter(item => item.fk_work_category !== null) // фильтруем только те, где fk_work_category не null
            .map(item => [item.fk_work_category, { id: item.fk_road, name: item.work_category }]) // создаем пары ключ-значение
          ).values() // берем только уникальные значения
        ).sort((a, b) =>  a - b) // сортируем
    return uniqueCategories
    },*/
    budgetSummary: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)
      const totalRecords = res.length
      const totalKGS = res.reduce((sum, item) => sum + item.amount_kgs, 0)
      const totalUSD = res.reduce((sum, item) => sum + item.amount_usd, 0)
      //const totalApproved = res.reduce((sum, item) => sum + item.approved, 0)
      const totalApproved = res.reduce((sum, item) => sum + (item.approved ? item.amount_kgs : 0), 0)
      const approved = totalKGS === totalApproved

      return {
        totalRecords,
        totalKGS,
        totalUSD,
        totalApproved,
        approved
      }
    },

    allocatedBudgetByRegionAndYear: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)

      // Return object like {periodic: 1000, routine: 100, approved: true}
      return res.reduce((acc, el) => {
        acc[el.fk_work_category == 4 ? 'periodic' : 'routine'] = el.amount_kgs
        acc['approved'] = Boolean(el.approved)
        return acc;
      }, {});
    },
  }
}
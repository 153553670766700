/* 
File: dropdowns.js
Description: returns lists for filling dropdowns.
*/
import Api from '@/api/dropdowns'

function filterAndSortList(data, filters = {}, fields, showAll = false) {
  let list = data
    .filter(item => {
      // Если фильтров нет, возвращаем все элементы или фильтруем по параметрам, если они заданы
      return Object.keys(filters).length === 0 ||
        Object.keys(filters).every(key => filters[key] === undefined || item[key] === filters[key])
    })
    .map(item => ({  // Преобразование элементов с использованием полей
      [fields.id]: item[fields.id],
      [fields.description]: item[fields.description]
    }))
    // Сортировка
    .sort((a, b) => a[fields.description].localeCompare(b[fields.description], undefined, { sensitivity: 'base' }))

  if (showAll) { // Добавляем элемент в начало, если showAll
    list.unshift({ [fields.id]: 0, [fields.description]: '' })
  }

  return list
}

export default {
  state: {
    roadnetwork: [],
    regions: [],
    deus: [],
    road_classes: [],
    roads: [],
    sections: [],
    work_categories: [],
    invtype_events: [],
    years: [],
    budget_years: [],
    import_types: []
  },

  actions: {
    async GET_NETWORK_DROPDOWN({ commit }, show_all = true) {
      const rn = [{ fk_rn: 1, roadnetwork: "Preliminary KGZ road network" }]

      if (show_all) rn.splice(0, 0, { fk_rn: 0, roadnetwork: '' })
      commit('SET_NETWORK_LIST', rn)
      return 'success'
    },

    async GET_REGIONS_DROPDOWN({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { rn = 1, show_all = true } = values
      try {
        const res = await Api.load_regions_dropdown_list(lang)
        const { status } = res
        if (status === 200) {
          let regionsList = filterAndSortList(
            res.data,
            { fk_rn: rn, active: true },  // Условия фильтрации
            { id: 'region_id', description: 'region_description' },  // Поля для преобразования
            show_all
          )
          commit('SET_REGION_LIST', regionsList)
          return 'success'
        } else {
          throw `Error GET_REGIONS_DROPDOWN: ${status}`
        }
      } catch (err) {
        throw `GET_REGIONS_DROPDOWN: ${err}`
      }
    },

    async GET_DEUS_DROPDOWN({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, show_all, prefix } = values
      try {
        const res = await Api.load_deu_dropdown_list(lang, region_id)
        const { status } = res
        if (status === 200) {
          res.data = res.data
            .sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))
            .map(item => {
              if (prefix) {
                return { ...item, description: `${prefix}-${item.description}` }
              }
              return item
            })
          if (show_all) { // Добавляем элемент в начало, если showAll
            res.data.unshift({ deu_id: 0, description: '' })
          }
          commit('SET_DEU_LIST', res.data)
          return 'success'
        } else {
          throw `Error GET_DEUS_DROPDOWN: ${status}`
        }
      } catch (err) {
        throw `GET_DEUS_DROPDOWN: ${err}`
      }
    },

    async GET_ROADS_CLASSES_DROPDOWN({ commit }, show_all = true) {
      const lang = global.localStorage["mps-locale"]
      /*
      try {
        const res = await Api.load_road_classes_dropdown_list(lang)
        const { status } = res
        if (status === 200) {
          let roadsList = res.data.filter((item) => { return item.fk_rn == rn })
          const cat_obj = {}
          roadsList.map((item) => {
            if (!cat_obj[item.road_class]) {
              cat_obj[item.road_class] = item.road_id
            }
          })

          const road_classes = Object.keys(cat_obj).map((item) => {
            return { road_class_id: item, road_class_name: item }
          }).sort(function (a, b) {
            return a.road_class_id.localeCompare(b.road_class_id)
          })
          */
      const road_classes = /*lang === 'en' ? [
        { road_class_id: "Zh", road_class_name: "Zh" },
        { road_class_id: "M", road_class_name: "M" },
        { road_class_id: "EM", road_class_name: "EM" }
      ] : */[
          { road_class_id: "Ж", road_class_name: "Ж" },
          { road_class_id: "М", road_class_name: "М" },
          { road_class_id: "ЭМ", road_class_name: "ЭМ" }
        ]

      if (show_all) { // Добавляем элемент в начало, если showAll
        road_classes.unshift({ road_class_id: '', road_class_name: '' })
      }

      commit('SET_ROAD_CLASSES_LIST', road_classes)
      return 'success'
      /*
      } else {
        throw `Error GET_ROADS_CLASSES_DROPDOWN: ${status}`
      }
    } catch (err) {
      throw `GET_ROADS_CLASSES_DROPDOWN: ${err}`
    }
    */
    },

    async GET_ROADS_DROPDOWN({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, show_all } = values

      try {
        const res = await Api.load_roads_dropdown_list(lang, region_id)
        const { status } = res
        if (status === 200) {
          res.data.sort((a, b) => a.road_description.localeCompare(b.road_description, undefined, { sensitivity: 'base' }))

          if (show_all) { // Добавляем элемент в начало, если showAll
            res.data.unshift({ road_id: 0, road_description: '' })
          }

          commit('SET_ROADS_LIST', res.data)
          return 'success'
        } else {
          throw `Error GET_ROADS_DROPDOWN: ${status}`
        }
      } catch (err) {
        throw `GET_ROADS_DROPDOWN: ${err}`
      }
    },

    async GET_SECTIONS_DROPDOWN({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, road_id, show_all } = values
      try {
        const res = await Api.load_sections_dropdown_list(lang, region_id, road_id)
        const { status } = res
        if (status === 200) {
          res.data.sort((a, b) => a.section_description.localeCompare(b.section_description, undefined, { sensitivity: 'base' }))

          if (show_all) { // Добавляем элемент в начало, если showAll
            res.data.unshift({ section_id: 0, section_description: '' })
          }

          commit('SET_SECTIONS_LIST', res.data)
          return 'success'
        } else {
          throw `Error GET_SECTIONS_DROPDOWN: ${status}`
        }
      } catch (err) {
        throw `GET_SECTIONS_DROPDOWN: ${err}`
      }
    },

    async GET_WORK_CATEGORIES_LIST({ commit }, show_all) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_work_categories_dropdown_list(lang)
        const { status } = res
        if (status === 200) {
          res.data = res.data.sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))
 
          if (show_all) { // Добавляем элемент в начало, если showAll
            res.data.unshift({ work_category_id: 0, description: '' })
          }

          commit('SET_CATEGORIES_LIST', res.data)   //categoriesTable);
          return 'success';
        } else {
          throw `Error LOAD_CATEGORIES_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_CATEGORIES_LIST: ${err}`
      }
    },

    // Review it:
    async GET_INVTYPE_EVENT_DROPDOWN({ commit, dispatch }) {
      const lang = global.localStorage["mps-locale"]
      //console.log(this.state.InventoryEvents)
      dispatch('LOAD_INVENTORY_EVENTS_LIST', lang).then(() => {
        const eventsList = this.state.InventoryEvents.list
        commit('SET_INVTYPE_EVENT_LIST', eventsList)
        return 'success'
        /*
                try {
                  const res = await Api.load_inventory_events_list(lang)
                  const { status } = res
                  if (status === 200) {
                    commit('SET_INVTYPE_EVENT_LIST', res.data)
                    return 'success';
                  } else {
                    throw `Error GET_INVTYPE_EVENT_DROPDOWN: ${status}`
                  }
                } catch (err) {
                  throw `GET_INVTYPE_EVENT_DROPDOWN: ${err}`
                }
              }
        */
      })
    },

    async GET_YEARS_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_years_dropdown_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_YEARS_LIST', res.data)   //categoriesTable);
          return 'success';
        } else {
          throw `Error GET_YEARS_LIST: ${status}`
        }
      } catch (err) {
        throw `GET_YEARS_LIST: ${err}`
      }
    },

    async GET_BUDGET_YEARS_LIST({ commit }, show_all = true) {
      try {
        const res = await Api.load_works_years_dropdown_list()
        const { status } = res
        if (status === 200) {

          if (show_all) res.data.unshift(0)

          commit('SET_BUDGET_YEARS_LIST', res.data)
        } else {
          throw `Load years list status: ${status}`
        }
      } catch (err) {
        throw `Load years list: ${err}`
      } finally {
      }
    },

    async GET_IMPORT_TYPES_DROPDOWN({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_import_types_dropdown_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_IMPORT_TYPES_LIST', res.data)
          return 'success';
        } else {
          throw `Error GET_IMPORT_TYPES_DROPDOWN: ${status}`
        }
      } catch (err) {
        throw `GET_IMPORT_TYPES_DROPDOWN: ${err}`
      }
    },
  },

  mutations: {
    SET_NETWORK_LIST(state, list) {
      state.roadnetwork = [...list]
    },
    SET_REGION_LIST(state, list) {
      state.regions = [...list]
    },
    SET_DEU_LIST(state, list) {
      state.deus = [...list]
    },
    SET_ROAD_CLASSES_LIST(state, list) {
      state.road_classes = [...list]
    },
    SET_ROADS_LIST(state, list) {
      state.roads = [...list]
    },
    SET_SECTIONS_LIST(state, list) {
      state.sections = [...list]
    },
    SET_CATEGORIES_LIST(state, list) {
      state.work_categories = [...list];
    },
    SET_INVTYPE_EVENT_LIST(state, list) {
      state.invtype_events = [...list];
    },
    SET_YEARS_LIST(state, list) {
      state.years = [...list];
    },
    SET_BUDGET_YEARS_LIST(state, list) {
      state.budget_years = [...list];
    },
    SET_IMPORT_TYPES_LIST(state, list) {
      state.import_types = [...list];
    },

  },
}
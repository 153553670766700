import axios from 'axios'

const API_ROOT = 'https://testapi.rams.kg'
//const API_ROOT = (process.env.NODE_ENV === "development") ? 'https://testapi.rams.kg' : 'https://rams.kg/api'
//const API_ROOT = (process.env.NODE_ENV === "development") ? 'https://192.168.31.42:5000/' : 'https://10.0.156.107:8080/'

const Api = axios.create({
  baseURL: API_ROOT,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer`
  }
})

export default {
  props: {
    Api: Api,
    type_json: { 'Content-Type': 'application/json' }
  },
}

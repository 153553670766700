/* 
File: reports.js
Description: Async functions for  Reports .
*/
import Api from '@/api/reports'

export default {
  state: {
    report_1_data: [],    // administrative_setup
    report_2_data: [],    // list_of_roads
    report_3_data: [],    // yearwise_report
    report_4_data: [],    // length_by_traffic
    report_5_data: [],    // road_way_details
    report_6_data: [],    // road_sections
    report_7_data: [],    // road_condition_summary
    report_8_data: [],    // road_condition_inxex
    report_9_data: [],    // yearly_condition_inxex
    report_10_data: [],   // section_wise_aadt
    report_11_data: [],   // total_elevation_zones
    report_12_data: [],   // road_length_by_elevation_zones
  },

  actions: {
    async REPORT_1_ADMINISTRATIVE_SETUP({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { inserted_date, region_id, road_class } = values
      try {
        const res = await Api.report_1_administrative_setup(lang, inserted_date, region_id)  //, road_class)
        if (res.status === 200) {
          commit('SET_REPORT_1', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_2_ROAD_INFO({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { inserted_date, region_id, deu_id, road_class } = values
      try {
        const res = await Api.report_2_roads_info(lang, inserted_date, region_id, deu_id, road_class)
        if (res.status === 200) {
          commit('SET_REPORT_2', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_3_YEARWISE_LENGTH({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { start_year, end_year, region_id, road_class, deu_id } = values
      try {
        const res = await Api.report_3_year_wise_road_length(lang, start_year, end_year, region_id, road_class, deu_id)
        if (res.status === 200) {
          commit('SET_REPORT_3', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_4_LENGTH_BY_TRAFFIC({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { start_year, end_year, region_id, road_class, deu_id } = values
      try {
        const res = await Api.report_4_road_length_by_traffic(lang, start_year, end_year, region_id, road_class, deu_id)
        if (res.status === 200) {
          commit('SET_REPORT_4', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_5_ROADWAY_DETAILS({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, road_class, deu_id, selected_date } = values
      try {
        const res = await Api.report_5_road_way_details(lang, region_id, road_class, deu_id, selected_date)
        if (res.status === 200) {
          commit('SET_REPORT_5', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_6_ROAD_SECTIONS({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, road_class, deu_id, selected_date } = values
      try {
        const res = await Api.report_6_sections(lang, region_id, road_class, deu_id, selected_date)
        if (res.status === 200) {
          commit('SET_REPORT_6', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_7_ROAD_CONDITION_DATA({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, deu_id, road_id, section_id, start_date, end_date } = values
      try {
        const res = await Api.report_7_road_condition_summary(lang, region_id, deu_id, road_id, section_id, start_date, end_date)
        if (res.status === 200) {
          commit('SET_REPORT_7', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_8_ROAD_CONDITION_INDEX({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, survey_year } = values
      try {
        const res = await Api.report_8_region_wise_condition(lang, region_id, survey_year)
        if (res.status === 200) {
          commit('SET_REPORT_8', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_9_YEARLY_CONDITION_INDEX({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, deu_id, road_id, section_id, start_year, end_year } = values
      try {
        const res = await Api.report_9_yearly_condition(lang, region_id, deu_id, road_id, section_id, start_year, end_year)
        if (res.status === 200) {
          commit('SET_REPORT_9', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_10_SECTION_WISE_AADT({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { selected_year, region_id, deu_id, road_id } = values
      try {
        const res = await Api.report_10_section_wise_aadt(lang, selected_year, selected_year, region_id, deu_id, road_id)
        if (res.status === 200) {
          commit('SET_REPORT_10', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_11_TOTAL_ELEVATION_ZONES({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.report_11_total_elevation_zones(lang)
        if (status === 200) {
          // remove next 4 lines after implementing via API
          const res = data.map(item => ({
            ...item,
            region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
          }))

          commit('SET_REPORT_11', res)  //res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_12_LENGTH_BY_ELEVATION_ZONES({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.report_12_road_length_by_elevation_zones(lang)
        if (status === 200) {
          // remove next 4 lines after implementing via API
          const res = data.map(item => ({
            ...item,
            region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
          }))

          commit('SET_REPORT_12', res)  //res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },
  },

  mutations: {
    SET_REPORT_1(state, list) {
      state.report_1_data = [...list]
    },
    SET_REPORT_2(state, list) {
      state.report_2_data = [...list]
    },
    SET_REPORT_3(state, list) {
      state.report_3_data = [...list]
    },
    SET_REPORT_4(state, list) {
      state.report_4_data = [...list]
    },
    SET_REPORT_5(state, list) {
      state.report_5_data = [...list]
    },
    SET_REPORT_6(state, list) {
      state.report_6_data = [...list]
    },
    SET_REPORT_7(state, list) {
      state.report_7_data = [...list]
    },
    SET_REPORT_8(state, list) {
      state.report_8_data = [...list]
    },
    SET_REPORT_9(state, list) {
      state.report_9_data = [...list]
    },
    SET_REPORT_10(state, list) {
      state.report_10_data = [...list]
    },
    SET_REPORT_11(state, list) {
      state.report_11_data = [...list]
    },
    SET_REPORT_12(state, list) {
      state.report_12_data = [...list]
    }
  },

  getters: {}
}
/* 
File: planned_treatments.js
Description: called from PlannedTreatments.vue
*/
import Api from '@/api/titlelist'

export default {
  state: {
    worksLists: [],     // List of worklists
    treatmentsList: [], // List of treatments with selected filters
    assignedTreatments: [], // List of treatments that are selected for budget assignment
  },
  actions: {
    async LOAD_WORKS_LISTS({ commit }, filters) {
      const { year, is_approved } = filters
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_works_lists(lang, year, is_approved)
        const { status } = res
        console.log('LOAD_WORKS_LISTS', status, res.data)
        if (status === 200) {
          commit('SET_WORKS_LISTS', res.data)
          return 'success'
        } else {
          throw `Error LOAD_WORKS_LISTS: ${status}`
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async GET_WORK_LIST_BY_ID({ commit }, work_list_id) {
      try {
        const res = await Api.get_work_list_by_id(work_list_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error GET_WORK_LIST_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async DEL_WORK_LIST({ commit }, work_list_id) {
      try {
        const res = await Api.del_work_list(work_list_id)
        if (res.status === 200) {
          commit('DEL_WORK_LIST', work_list_id)
          return 'success'
        } else {
          throw new Error(`Error DEL_WORK_LIST: ${res.status}`)
        }
      } catch (err) {
        console.error('Caught error:', err)
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async GENERATE_WORKS({ dispatch }, year) {
      try {
        const res = await Api.generate_works(year)
        const { status } = res

        if (status === 201) {
          //dispatch('LOAD_ALL_WORKS', {work_list_id: res.data.work_list_id})
          return res.data   // API returns Object with data on created worklist
        } else {
          throw new Error(`Error GENERATE_WORKS: ${status}`)
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return 'exists'
        } else if (err.response) {
          throw new Error(err.response.data.message || 'Unknown error')
        } else {
          console.error('Unexpected error:', err)
          throw new Error('Network or client-side error')
        }
      }
    },

    async LOAD_ALL_WORKS({ commit }, filters) {
      const {
        work_list_id, region_id, is_plan_assigned, is_budget_assigned, is_approved, plan_id, budget_id
      } = filters
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_all_works(
          lang, work_list_id, region_id, is_plan_assigned,
          is_budget_assigned, is_approved, plan_id, budget_id
        )
        const { status } = res
        if (status === 200) {
          commit('SET_GENERATED_TREATMENTS', res.data)
          return 'success'
        } else {
          throw `Error LOAD_ALL_WORKS: ${status}`
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ASSIGN_PLAN({ commit }, payload) {
      //const { plan_id, work_list_id } = payload
      try {
        const res = await Api.assign_plan(payload)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async ASSIGN_BUDGET({ commit }, payload) {
      try {
        const res = await Api.assign_budget(payload) 
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UNASSIGN_BUDGET({ commit }, payload) {
      try {
        const res = await Api.unassign_budget(payload) 
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async POPULATE_TREATMENTS({ state, rootGetters, commit, dispatch }, { work_list_id, region, year }) {
      // As this action is called from the budget screen we assume that state.list already has the data
      // Convert the budgetsByCategory array to an object for quick access by category
      const categoryLimits = rootGetters['budgetsByCategory'](region, year).reduce(
        (acc, item) => { acc[item.fk_work_category] = { sum: item.amount_kgs, id: item.budget_id }; return acc }, {}
      )

      // clear the list
      commit('SET_ASSIGNED_TREATMENTS_LIST', [])
      try {
        const lang = global.localStorage["mps-locale"]
        // Returned back filtering by the plan assignment
        const { status, data } = await Api.load_all_works(lang, work_list_id, region, 1, 0, 0)
        //const { status, data } = await Api.load_all_works(lang, work_list_id, region, null, 0, 0)
        if (status !== 200) {
          throw new Error(`POPULATE_TREATMENTS status: ${status}`)
        }

        // Order by the Priority Index in descending mode
        const sortedData = data.sort((a, b) => {
          let res = b.priority_index - a.priority_index
          if (res == 0) { res = b.priority_index - a.priority_index }
          if (res == 0) { res = b.cost - a.cost }
          return res
        })

        // Initiate fk_work_category and budget limits in one reduce call
        const resultArray = sortedData.reduce((acc, item) => {
          const category = item.fk_work_category
          const limitObj = categoryLimits[category]

          if (!limitObj) { return acc }   // skip if there is no such category

          // Initialize the sum for a category if it hasn't been encountered yet
          if (!acc.categoryTotals[category]) { acc.categoryTotals[category] = 0 }

          // Update the accumulated amount
          const updatedTotal = acc.categoryTotals[category] + item.total_cost

          // If the accumulated amount is less than the category limit, we add the element to the result
          if (updatedTotal <= limitObj.sum) {
            acc.result.push({ ...item, running_total: updatedTotal, fk_budget: limitObj.id });
            acc.categoryTotals[category] = updatedTotal
          }
          return acc;
        }, { result: [], categoryTotals: {} })
        // Save the result
        commit('SET_ASSIGNED_TREATMENTS_LIST', resultArray.result)
        dispatch('SAVE_ASSIGNED_TREATMENTS')

        return resultArray.result.length
      } catch (err) {
        throw new Error(`Error POPULATE_TREATMENTS: ${err}`)
      }
    },

    async SAVE_ASSIGNED_TREATMENTS({ state, commit, dispatch }) {

      // restore it after Andrey resolves ticket #83

      if (state.assignedTreatments.length == 0) {
        return 0
      }
      // Группируем результат по категориям и собираем массив id для каждой категории
      const groupedResult = state.assignedTreatments.reduce((acc, item) => {
        const budget_id = item.fk_budget
        const id = item.treatment_id

        // Если категория еще не добавлена, инициализируем объект для неё
        const existingBudgetId = acc.find(c => c.budget_id === budget_id)
        if (existingBudgetId) {
          existingBudgetId.treatment_id.push(id)
        } else {
          acc.push({
            budget_id: budget_id,
            treatment_id: [id]
          })
        }
        return acc
      }, [])
      // Save and return the result
      try {
        const res = await Api.assign_budget({ data: groupedResult })
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async RESET_WORK_LISTS({ commit }) {
      commit('SET_WORKS_LISTS', [])
    },

    async RESET_TREATMENTS({ commit }) {
      commit('SET_GENERATED_TREATMENTS', [])
    }
  },

  mutations: {
    SET_WORKS_LISTS(state, list) {
      state.worksLists = [...list]
    },

    DEL_WORK_LIST(state, id) {
      let ind = state.worksLists.findIndex((row) => row.work_list_id === id)
      if (ind >= 0) state.worksLists.splice(ind, 1)
    },

    SET_GENERATED_TREATMENTS(state, list) {
      state.treatmentsList = [...list]
    },

    SET_ASSIGNED_TREATMENTS_LIST(state, list) {
      //state.treatmentsList = [...list]
      state.assignedTreatments = [...list]
    },

    DELETE_ASSIGNED_TREATMENTS(state, payload) {
      // Удаление объектов, которые соответствуют budget_id и хотя бы одному значению из section_maintenance_plan_id
      state.assignedTreatments = state.assignedTreatments.filter(item => {
        // Если budget_id не совпадает, оставить элемент
        //if (item.budget_id !== payload.budget_id) {
        //   return true;
        // }

        // Проверить, если хотя бы один элемент treatment_id совпадает
        return !payload.includes(item.treatment_id);
      });
    }
  },

  getters: {
    treatmentsSummary: (state) => {
      const totalRecords = state.treatmentsList.length
      const totalSum = state.treatmentsList.reduce((sum, work) => sum + work.cost, 0)
      const totalLength = state.treatmentsList.reduce((sum, work) => sum + work.units, 0)

      return {
        totalRecords,
        totalSum,
        totalLength
      }
    },

    assignedTreatments: (state) => (budget_id) => {
      return (!budget_id) ? state.assignedTreatments : state.assignedTreatments.filter(item => item.fk_budget === budget_id)
    },

    assignedTreatmentsSummary: (state, getters) => (budget_id) => {
      const assignedTreatments = getters.assignedTreatments(budget_id)
      const totalRecords = assignedTreatments.length
      //const ind = assignedTreatments[totalRecords-1].running_total
      //console.log(ind)
      const totalSum = assignedTreatments.reduce((sum, work) => sum + work.cost, 0)
      const totalLength = assignedTreatments.reduce((sum, work) => sum + work.units, 0)

      return {
        totalRecords,
        totalSum,
        totalLength
      }
    },

    // Delete it ??? as it is seemss slower than fitering in the component
    treatmentsByPlanId: (state) => (plan_id) => {
      if (!plan_id) {
        return state.treatmentsList
      }
      return state.treatmentsList.filter(work => work.fk_plan === plan_id)
    },

    treatmentsSummaryByRegion: (state, getters) => (region_id) => {
      const filteredWorks = getters.treatmentsByRegion(region_id)
      const totalRecords = filteredWorks.length
      const totalSum = filteredWorks.reduce((sum, work) => sum + work.cost, 0)
      const totalLength = filteredWorks.reduce((sum, work) => sum + work.units, 0)

      return {
        totalRecords,
        totalSum,
        totalLength
      }
    }
  }
}

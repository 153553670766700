/* 
File: work_plan.js
Description: provides main routes for the WorkPlan, budgeting and HDM forms, component of the routes.js
*/
const Preparatory = () => import('@/pages/WorkPlanPrep/Preparatory.vue')
const PrintTitleList = () => import('@/pages/WorkPlan/PrintTitleList.vue')
const TitleList = () => import('@/pages/WorkPlan/TitleList.vue')
//---
const MaintenancePlan_all = () => import('@/pages/WorkPlan/MaintenancePlan_all.vue')
const MaintenancePlan_single = () => import('@/pages/WorkPlan/MaintenancePlan_single.vue')
//---

const WorkPlanMenu = [
  {
    path: 'preparatory_steps',
    name: 'preparatory_steps',
    component: Preparatory
  },

  {
    path: 'PrintTitleList',
    name: 'PrintTitleList',
    components: { default: PrintTitleList },
  },

  {
    path: 'title_list',
    name: 'title_List',
    component: TitleList
  },
//---
  {
    path: 'budget/show_all',
    name: 'budget_show_all',
    component: MaintenancePlan_all
  },
  {
    path: 'budget/show',
    name: 'budget_show_populated',
    component: MaintenancePlan_single
  },
//---
]

export default WorkPlanMenu
/* 
File: homo_sections.js
Description: provides routines for dealing with homogeneous sections.
*/
import Api from '@/api/homo_sections'

export default {
  state: {
    list: [],
    hsList: []
  },

  actions: {
    async LOAD_HS_LIST({ commit }, filters) {
      const { year, is_approved } = filters
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_hs_lists(lang, year, is_approved)
        const { status } = res
        if (status === 200) {
          commit('SET_HS_LIST', res.data)
          return 'success'
        } else {
          throw `Error LOAD_HS_LIST: ${status}`
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async GET_HS_LIST_BY_ID({ }, homogeneous_section_list_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.get_hs_list_by_id(lang, homogeneous_section_list_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error GET_HS_LIST_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async LOAD_ALL_HS({ commit }, homogeneous_section_list_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_all_hs(homogeneous_section_list_id)
        const { status } = res
        if (status === 200) {
          commit('SET_HS', res.data)
          return 'success'
        } else {
          throw `Error LOAD_ALL_HS: ${status}`
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async GENERATE_HS({ }, params) {
      const { year_from, year_to, min_hs_length, condition_index_threshold } = params
      try {
        const res = await Api.generate_hs(year_from, year_to, min_hs_length, condition_index_threshold)
        const { status } = res
        if (status === 201) {
          return res.data   // API returns Object with data on created worklist
        } else {
          throw new Error(`Error GENERATE_HS: ${status}`)
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return 'exists'
        } else if (err.response) {
          throw new Error(err.response.data.message || 'Unknown error')
        } else {
          console.error('Unexpected error:', err)
          throw new Error('Network or client-side error')
        }
      }
    },

    async DEL_HS_LIST({ commit }, homogeneous_section_list_id) {
      try {
        const res = await Api.del_hs_list(homogeneous_section_list_id)
        if (res.status === 200) {
          commit('DEL_HS', homogeneous_section_list_id)
          return 'success'
        } else {
          throw new Error(`Error DEL_HS_LIST: ${res.status}`)
        }
      } catch (err) {
        console.error('Caught error:', err)
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async APPROVE_HS_LIST({ }, homogeneous_section_list_id) {
      try {
        const res = await Api.approve_hs_list(homogeneous_section_list_id)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async CANCEL_HS_LIST({ }, homogeneous_section_list_id) {
      try {
        const res = await Api.cancel_hs_list(homogeneous_section_list_id)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async RESET_HS_LIST({ commit }) {
      commit('SET_HS_LIST', [])
    },

    async RESET_HS({ commit }) {
      commit('SET_HS', [])
    },

  },

  mutations: {
    SET_HS_LIST(state, list) {
      state.list = list
    },

    SET_HS(state, list) {
      state.hsList = list
    },

    DEL_HS(state, id) {
      let ind = state.list.findIndex((row) => row.homogeneous_section_list_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {
    // Unique years in the HomoSections list
    yearsInHSList: (state) => (addEmptyItem = true) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year !== null) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ year }));

      if (addEmptyItem) uniqueYears.unshift({ year: '' })   // Add null value to the beginnig
      return uniqueYears
    },

    hsListByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(row => row.year === year)
    },

    //Homosections
    roadsInList: (state) => (region_id) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueRoads = Array.from(
        new Map(state.hsList
          .filter(item => !region_id || item.fk_region === region_id) // фильтруем по региону
          .map(item => [item.fk_road, { id: item.fk_road, name: item.road_description }]) // создаем пары ключ-значение
        ).values() // берем только уникальные значения
      ).sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })); // сортируем по имени
      
      uniqueRoads.unshift({ id: 0, name: '' })   // Add null value to the beginnig
      return uniqueRoads
    },
    homoSectionsFiltered: (state) => (region_id, road_id) => {
      return state.hsList.filter(row => {
        const regionMatch = !region_id || row.fk_region === region_id
        const roadMatch = !road_id || row.fk_road === road_id
        return regionMatch && roadMatch
      }).sort((a, b) => a.start_distance - b.start_distance)
    },
    homoSectionSummary: (state, getters) => (region_id, road_id) => {
      const roadsCount = getters.roadsInList(region_id).length
      const filteredSections = getters.homoSectionsFiltered(region_id, road_id)
      const sectionsCount = filteredSections.length
      const sectionsLength = filteredSections.reduce((sum, row) => sum + (row.end_distance - row.start_distance), 0) / 1000

      return {
        roadsCount,
        sectionsCount,
        sectionsLength
      }
    }

  }
}
/* 
File: api/titlelist.js
Description: API connections for the async functions for the Works list and Title list generation.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {

  /*---------  Work list ---------------------*/
  load_works_lists(lang = 'en', year, is_approved) {
    let apiStr = `/work_list/all?lang=${lang}`
    if (year) apiStr += `&year=${year}`
    if (is_approved!=null) apiStr += `&is_approved=${is_approved}`   // changed to !==null as we accept 0 as the value
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  get_work_list_by_id(work_list_id) {
    return Api.get(`/work_list/${work_list_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  del_work_list(work_list_id) {
    return Api.delete(`/work_list/${work_list_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  generate_works(year) {
    return Api.post(`/work_list/generate_works/${year}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_all_works(lang = 'en', work_list_id, region_id, is_plan_assigned, is_budget_assigned, is_approved, plan_id, budget_id) {
    let apiStr = `/work_list/all_works/${work_list_id}?lang=${lang}`
    if (region_id!=null) apiStr += `&region_id=${region_id}`
    if (is_plan_assigned!=null) apiStr += `&is_plan_assigned=${is_plan_assigned}`
    if (is_budget_assigned!=null) apiStr += `&is_budget_assigned=${is_budget_assigned}`
    if (is_approved!=null) apiStr += `&is_approved=${is_approved}`
    if (plan_id) apiStr += `&plan_id=${plan_id}`
    if (budget_id) apiStr += `&budget_id=${budget_id}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  assign_plan(payload) {
    return Api.put(`/work_list/assign_plan/`, payload, {
        headers: {
        ...type_json
      }
    })
  },
  assign_budget(payload) { 
    return Api.put(`/work_list/assign_budget/`, payload, {
        headers: {
        ...type_json
      }
    })
  },
  unassign_budget(payload) { 
    return Api.put(`/work_list/unassign_budget/`, payload, {
        headers: {
        ...type_json
      }
    })
  }
}
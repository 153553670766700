import Api from '@/api/login'

export default {
    state: {
        token: localStorage.getItem('mps-token') || '',
        authStatus: '',
        me: {}
    },
    actions: {
        async LOGIN({commit}, formData) {
            commit('AUTH_REQUEST')
            const {login, password} = formData

            try {
                const resp = await Api.login({login, password})
                const code = resp.status
                const {access_token} = resp.data
                if (code === 200 && access_token) {
                    localStorage.setItem('mps-token', access_token)
                    commit('AUTH_SUCCESS', access_token)
                    commit('SET_HEADER_AUTH')
                } else {
                    throw Error('Authorization error')
                }
            } catch (err) {
                commit('AUTH_ERROR')
                localStorage.removeItem('mps-token') // if the request fails, remove any possible user token if possible
            }
        },
        async LOGOUT({commit}) {
            localStorage.removeItem('mps-token')
            await Api.logout()
            await Api.delHeaderAuth()
            commit('AUTH_LOGOUT')
        },
        async GET_MY_PROFILE({commit, dispatch}) {
            try {
                const result = await Api.get_profile()
                commit('SET_USER', result.data)
            } catch (e) {
                dispatch('LOGOUT')
                throw Error('Getting profile error')
            }
        },

        async PASSWORD_RECOVERY({}, email) {
            try {
                const resp = await Api.password_recovery_request(email)
                const code = resp.status
                if (code !== 202) {
                    throw 'Email did not find'
                }
            } catch (err) {
                const message = err.response.hasOwnProperty('data')
                    ? err.response.data.msg
                    : `Unknown error: ${err}`
                throw message
            }
        }
        // async SAVE_RECOVERY_PASSWORD({commit}, payload) {
        //   const {token, password} = payload
        //   try {
        //     const resp = await Api.save_req_pass(token, password)
        //     const code = resp.status
        //     if (code !== 200) {
        //       console.log(result)
        //     }
        //   } catch (error) {}
        // }
    },
    mutations: {
        AUTH_REQUEST: (state) => {
            state.authStatus = 'loading'
        },
        AUTH_SUCCESS: (state, token) => {
            state.token = token
            state.authStatus = 'success'
        },
        AUTH_ERROR: (state) => {
            state.authStatus = 'error'
        },
        AUTH_LOGOUT: (state) => {
            state.authStatus = ''
            state.token = ''
            state.me = {}
        },
        SET_HEADER_AUTH: (state) => {
            if (!Api.isSetHeaderAuth()) {
                Api.setHeaderAuth(state.token)
            }
        },
        SET_USER: (state, _profile) => {
            state.me = {..._profile}
        }
    },
    getters: {
        hasToken: (state) => Boolean(state.token),
        profileLoaded: (state) => Object.keys(state.me) > 0,
        amIAdmin: (state) => state.me.role === 'admin',
        amIFromRMD: (state) => state.me.access_level === 0
    }
}
